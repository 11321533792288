import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'

import accessMap from '../images/access_map.jpeg'
import Layout from '../layouts/Layout'
import SEO from '../components/SEO'

const AccessPage = () => {
  return (
    <Layout>
      <h1 className="text-4xl text-center mb-14 font-black">アクセス</h1>
      <div className="flex flex-col-reverse lg:flex-row space-x-0 lg:space-x-5 justify-center">
        <div className="w-full lg:w-2/5 flex flex-col space-y-5">
          <div className="bg-white p-3 rounded-md">
            <h1 className="text-2xl lg:text-3xl mb-5 lg:mb-4 break-words font-bold">
              とんかつの店 馬酔木（あしび）
            </h1>
            <div className="space-y-5">
              <div className="flex items-start content ">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="mr-2 mt-1 w-5 h-5 text-main"
                />
                <a
                  href="https://goo.gl/maps/nL4e9hQRAUmiM8aJA"
                  target="_blank"
                  className="underline"
                >
                  〒224-0065 横浜市都筑区高山 14-5 エスパシオ高山
                </a>
              </div>
              <div className="flex items-center content ">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="mr-2 w-5 h-5 text-main"
                />
                <a href="tel:045-941-9544" className="underline">
                  045-941-9544
                </a>
              </div>
            </div>
          </div>
          <div className="bg-white p-3 rounded-md">
            <h4 className="font-black text-lg mb-2">
              東名高速青葉ICから来る場合
            </h4>
            <ul>
              <li>１、【246市ヶ尾】交差点　を右折</li>
              <li>２、【東福寺西側】交差点　を左側(道なり)に進む</li>
              <li>３、【富士見が丘】交差点　を直進</li>
              <li>
                ４、【前高山】交差点　を左折後2本目の路地を左折 ⇒
                左側四軒目の白い建物です。
              </li>
            </ul>
          </div>
          <div className="bg-white p-3 rounded-md">
            <h4 className="font-black text-lg mb-2">
              第三京浜港北ICから来る場合
            </h4>
            <ul>
              <li>１、第三京浜港北インター下りすぐ右折</li>
              <li>
                ２、【第三京浜入り口】交差点　を左折そのまま【緑産業道路】を直進
              </li>
              <li>３、【新整備(ららぽーと手前)】交差点　を右折</li>
              <li>４、【開戸トンネル】を直進</li>
              <li>
                ５、【前高山】交差点　を右折を左折後2本目の路地を左折 ⇒
                左側四軒目の白い建物です。
              </li>
            </ul>
          </div>
          <div className="bg-white p-3 rounded-md">
            <h4 className="font-black text-lg mb-2">電車＆徒歩の場合</h4>
            <ul>
              <li>市営地下鉄　ふれあいの丘駅下車 徒歩５分</li>
            </ul>
          </div>
        </div>
        <div className="mb-5 lg:mb-0 w-full lg:w-2/5">
          <img
            className="rounded-md overflow-hidden w-full"
            alt="ashibi-map"
            src={accessMap}
          />
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="アクセス | とんかつの店 馬酔木 横浜市都筑区" />
)

export default AccessPage
